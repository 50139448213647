.mainWrapper {
    margin-top: 20px;
}

.mainTitle {
    font-family: 'DIN Pro Medium', sans-serif;
    font-weight: 900;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
}

.mainBonus {
    display: flex;
    gap: 8px;
    max-width: 345px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 25px;
}

.textBonus {
    font-family: 'DIN Pro', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
}

.Points {
    width: 61px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 900;
    font-family: 'DIN Pro', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainGreen {
    font-family: 'DIN Pro Regular', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #43B02A;
    margin-bottom: 16px;
}

.mainCards {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 52px;
}

.mainButton button {
    width: 345px;
    height: 53px;
    background: #000000;
    border-radius: 24px;
    border: none;
    outline: none;
    font-family: 'DIN Pro Medium', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

.wrapperPromo {
    max-width: 345px;
    width: 100%;
    background: #EBEBEB;
    padding: 23px 0 29px 23px;
    border-radius: 12px;
}

.titlePromo {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-family: 'DIN Pro Black', sans-serif;
    margin-bottom: 13px;
}

.handPromo {
    display: flex;
    gap: 11px;
    margin-bottom: 16px;
}

.textPromo {
    font-size: 16px;
    font-weight: 400;
    font-family: 'DIN Pro', sans-serif;
    line-height: 18px;
}


.wrapperFive {
    max-width: 231px;
    width: 100%;
    background: #EBEBEB;
    padding: 27px 0 29px 19px;
    border-radius: 12px;
}

.textFive {
    font-size: 16px;
    font-weight: 700;
    font-family: 'DIN Pro Bold', sans-serif;
    line-height: 20px;
    padding-left: 4px;
    margin-bottom: 27px;
}

.buttonStart  button {
    background: #43b02a;
    border-radius: 24px;
    outline: none;
    border: none;
    max-width: 179px;
    width: 100%;
    height: 45px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
    font-family: 'DIN Pro', sans-serif;
    text-align: center;
}


.wrapperQR {
    max-width: 345px;
    width: 100%;
    background: #EBEBEB;
    padding: 25px 0 29px 22px;
    border-radius: 12px;
}

.titleQR {
    font-family: 'DIN Pro', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 17px;
}

.iphoneBlock {
    display: flex;
    gap: 17px;
    font-family: 'DIN Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
}

.qrBlock {
    display: flex;
    gap: 13px;
    align-items: flex-end;
    font-family: 'DIN Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

@media (max-width: 650px) {
    .mainWrapper {
        margin: 16px auto 0;
        padding: 0 0px;
    }

    .mainTitle, .mainGreen {
        text-align: center;
    }

    .wrapperFive {
        max-width: 345px;
        padding: 27px 0px 26px 18px;
    }

    .fiveMobile {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    .textFive {
        padding-left: 0;
        margin-bottom: 0;
        flex: 1;
        font-size: 15px;
        margin-right: 10px;
    }

    .wrapperQR {
        padding: 19px 25px 18px 19px;
    }

    .iphoneBlock {
        align-items: flex-start;
        gap: 12px;
        font-weight: 700;
        margin-bottom: 15px;
    }

}