.wrapperControllBtn {
    display: flex;
    justify-content: flex-end;
    height: 33px;
    width: 100%;
}

.controllBtn {
    border-radius: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.controllBtn:active {
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.controllBtnClickedOutside {
    width: fit-content;
    border-radius: 100px;
    background: #43B02A;
    display: flex;
    align-items: center;
    padding: 9px 13px;
    height: 33px;
    cursor: pointer;
}

.minus {
    width: 33px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 13px;
    flex: 1;
    justify-content: flex-start;
}

.plus {
    width: 33px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 13px;
    flex: 1;
    justify-content: flex-end;
}

.amount {
    color: #fff;
    font-weight: 500;
    line-height: 17px;
    font-size: 14px;
    font-family: 'DINPro-Medium', sans-serif;
}

.maxBG {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: #ffffff70;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggler {
    height: 82px;
    width: 36px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background: #ededed;
    border-radius: 0 8px 0 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
}

.toggler .firstToggle, .toggler .secondToggle {
    color: #000;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    font-family: 'DINPro-Medium', sans-serif;
    cursor: pointer;
}

.firstToggle {
    height: 40px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 2px 0 0;
}

.secondToggle {
    height: 42px;
    width: 36px;
    border-radius: 0 0 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.percents {
    font-family: 'DINPro-Medium', sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff7834;
    color: #fff;
    border-radius: 4px;
    width: 32px;
    height: 16px;
}

.productPriceAndToggle {
    height: 48px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.oldPriceWrapper {
    display: flex;
}

.oldPrice {
    color: #BFBFBF;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    font-family: 'DINPro-Bold', sans-serif;
    display: flex;
    align-items: flex-start;
    margin-right: 7px;
    position: relative;
}

.oldPrice:before {
    content: "";
    border-bottom: 1px solid #BFBFBF;
    position: absolute;
    top: 0;
    left: -1px;
    width: 110%;
    height: 50%;
}

.oldPriceCent{
    color: #BFBFBF;
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
    font-family: 'DINPro-Regular', sans-serif;
}

.per {
    color: #FF7834;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    font-family: 'DINPro-Bold', sans-serif;
}

.priceAndBtn {
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'DINPro-Bold', sans-serif;
    width: 100%;
    position: relative;
    margin: 0 auto;
}

.price {
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
}

.priceCent {
    color: #333333;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    font-family: 'DINPro-Bold', sans-serif;
}