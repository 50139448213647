.switch {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: #fff;
}

.background {
    width: 50px;
    height: 30px;
    border-radius: 15px;
    padding: 2px;
    display: flex;
    align-items: center;

}