.supportBtn {
    width: 290px;
    height: 36px;
    padding: 6px 0;
    background: #FF7834;
    border-radius: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

a:hover {
    text-decoration: none;
    color: #fff;
}

.statusCircle {
    width: 53px;
    height: 53px;
    background: #EDEDED;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
}

.statusActive {
    background: #43B02A;
}

.statusWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 31px;
}

.courierCard {
    width: 354px;
    height: 120px;
    background: #EDEDED;
    border-radius: 24px;
    padding: 15px 18px 20px 18px;
}

.image {

    margin-right: 12px;
}

.image img {
    height: 35px;
    border-radius: 50%;
    width: 35px;
}

.courier {
    display: flex;
    align-items: center;
    color: #213140;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    font-family: 'DINPro-Bold', sans-serif;
    margin-bottom: 14px;
}

.contact {
    width: 149px;
    height: 36px;
    background: #FF7834;
    border-radius: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    font-family: 'DINPro-Bold', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.courierOnMap {
    width: 149px;
    height: 36px;
    background: #43B02A;
    border-radius: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    font-family: 'DINPro-Bold', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.courierBtns {
    display: flex;
    justify-content: space-between;
}

.supportBtnWrapper {
    margin: 15px 0 0;
}

.supportNumber {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: #bdbdbd;
    font-family: 'DINPro-Regular', sans-serif;
    max-width: 350px;
    text-align: right;
    margin-bottom: 20px;
}

.supportNumber a:hover {
    color: #0055A5;
}

.supportNumber a {
    color: #0055A5;
    text-decoration: underline;
}

@media (max-width: 420px) {
    .statusCircle {
        width: 53px;
        height: 53px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
    }

    .orderStatus, .courierCard, .supportBtn {
        margin: 0 auto;
    }

    .supportBtnWrapper {
        margin-bottom: 0;
    }

    .courierCard {
        margin-bottom: 0;
    }

    .statusWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 335px;
        margin: 0 auto 25px;
    }
}