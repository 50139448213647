.root {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    height: 32px;
    padding-inline-end: 8px;
  }
  
  .expandIconWrapper {
    align-items: center;
    font-size: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
  }
  
  .expandIconWrapper.isOpen {
    transform: rotate(90deg);
  }
  
  .labelGridItem {
    padding-inline-start: 8px;
  }
  
  .inputWrapper {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: flex-start;
  }
  
  .textField input {
    font-size: 12px !important;
    padding: 2px !important;
  }
  
  .editButton {
    padding: 4px !important;
  }

  .editButton svg {
      height: 12px !important;
  }
  
  .editIcon {
    font-size: 14px !important;
  }
  
  .nodeInput {

  }
  
  .nodeLabel {
    padding-right: 15px;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  