.wrapper {
    margin-top: 37px;
    margin-left: 62px;
}

.wrapper > img {
    width:798px;
    margin-bottom: 12px;
}

.title {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    font-family: 'DIN Pro', sans-serif;
    line-height: 20px;
    margin-bottom: 20px;
}

.text {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    font-family: 'DIN Pro', sans-serif;
    line-height: 17px;
    margin-bottom: 20px;
}

.greenText {
    color: #62B401;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    font-family: 'DIN Pro', sans-serif;
    margin-bottom: 8px;
}

.textSale {
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    color: #000;
    font-family: 'DIN Pro', sans-serif;
    margin-bottom: 40px;
}

.button {
    background: #7AE000;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    font-family: 'DIN Pro', sans-serif;
    text-align: center;
    outline: none;
    border: none;
    width: 243px;
    height: 51px;
    border-radius: 40px;
}

.button:hover {
    background: #6DBF0A;
}

@media (max-width: 768px) {
    .wrapper {
        max-width: 100%;
        margin: 0;
        padding: 16px;
    }
    .wrapper > img {
        max-width: 100%;
    }

    .title {
        margin-bottom: 12px;
    }

    .greenText {
        margin-bottom: 25px;
        text-align: center;
    }

    .textSale {
        margin-bottom: 15px;
        text-align: center;
    }

    .centerButton {
        display: flex;
        justify-content: center;
    }

    .button:hover {
        background: #6DBF0A;
    }
}
