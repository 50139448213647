.anketaBtn {
    width: 155px;
    height: 45px;
    background: #FF7834;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
}

.ecoPerMinute_block {
    padding: 15px 0 25px 25px;
    background: #EBEBEB;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    max-width: 505px;
}

.ecoPerMinute_block_order {
    padding: 15px 20px 25px 20px;
    background: #EBEBEB;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    max-width: 445px;
}

.orderNumber {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    color: #383838;
    font-family: 'DIN Pro Bold', sans-serif;
    margin-bottom: 15px;
}

.orderDigit {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    font-weight: 700;
    color: #383838;
    font-family: 'DIN Pro Bold', sans-serif;
    margin-bottom: 15px;
}

.promocodeBlock {
    padding: 20px 10px 20px 20px;
    background: #EBEBEB;
    border-radius: 12px;
    display: flex;
    max-width: 445px;
    margin: 0 auto 15px;
}

@media (max-width: 550px) {
    .promocodeBlock {
        flex-wrap: wrap;
    }
}

.orderWrapper {
    padding: 0 25px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.promocode_inner_block {
    width: 200px;
    margin-right: 20px;
}


.promocode_inner_block input {
    background: #fbfbfb;
    border-radius: 4px;
    display: block;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 24px;
    color: #404040;
    margin-bottom: 27px;
    font-family: 'DIN Pro Bold', sans-serif;
    line-height: 30px;
    flex-shrink: 0;
    border: solid 1px rgba(255, 255, 255, 0.1);
    width: 200px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.promocode_inner_block input.with_error {
    color: rgb(255 0 0);
    border-color: rgb(255 0 0);
}

@media (max-width: 550px) {
    .promocode_inner_block {
        width: 100%;
        margin-right: 0;
    }

    .promocode_inner_block input {
        width: 100%;
    }
}

.bonuses_anketa_way_flex_left {
    font-size: 12px;
    line-height: 20px;
    color: #404040;
    font-weight: 400;
    width: 239px;
    /*font-family: 'DINPro-Regular', sans-serif;*/
    margin-right: 25px;
}

.bonuses_anketa_way_flex_right a {
    background: #FF7834;
    border-radius: 24px;
    width: 140px;
    font-size: 14px;
    line-height: 45px;
    height: 45px;
    text-align: center;
    color: #404040;
    display: block;
    font-weight: 900;
    text-decoration: none !important;
    font-family: 'DIN Pro Bold', sans-serif;
}

@media (max-width: 550px) {
    .bonuses_anketa_way_flex_right a {
        width: 160px;
    }
}

.input input {
    max-width: 245px;
    width: 100%;
    height: 50px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    outline: none;
    padding: 14px 20px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'DINPro-Regular', sans-serif;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.input {
    width: 60%;
    max-width: 245px;
    margin-right: 15px;
}

.inputWrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
}

.btn {
    min-width: 150px;
    max-width: 200px;
    width: 40%;
    height: 50px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 550px) {
    .ecoPerMinute_block {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    .orderWrapper {
        padding: 0 15px;
    }
}

.standBtn {
    width: 160px;
    border-radius: 24px;
    height: 45px;
    background: #43B02A;
    display: flex;
    align-items: center;
    justify-content: center;
}

.onMainBtn {
    width: 78%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF7834;
    height: 50px;
    border-radius: 30px;
    margin-top: 60px;
    cursor: pointer;
}

.mainBtnWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.reviewWrapper {
    padding: 15px 35px 15px 17px;
    background: #EBEBEB;
    border-radius: 12px;
    max-width: 445px;
    margin: 0 auto 15px;
}

.reviewText {
    color: #404040;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    font-family: 'DINPro-Bold', sans-serif;
    margin-bottom: 16px;
}

.browsers {
    display: flex;
    align-items: center;
}

.yandex, .google {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
}

.yandex {
    margin-right: 15px;
}

.yandex .image img {
    width: 81px;
    margin-bottom: 9px;
}

.google .image img {
    width: 84px;
    margin-bottom: 4px;
}

.reviewBtn {
    width: 143px;
    height: 35px;
    background: #404040;
    border-radius: 8px;
    font-family: 'DINPro-Medium', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reviewBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    cursor: pointer;
}